import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
