export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  fuelSavings: number;
  stealthHours: number;
  isInStealthMode: boolean;
  solarWatts: number;
  alternatorAmps: number;
  faultCodes: string;
  hasFaultCode: boolean;
  carbonDioxideSaved: number;
  fuelLevel: number;
  odometer: number;
  stateOfCharge: number;
  stealthAmps: number;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Metrics;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;