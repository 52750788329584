import {
  VehicleDispatchTypes,
  VEHICLES_LOADING,
  VEHICLES_FAILURE,
  VEHICLES_SUCCESS,
  Vehicles,
} from "../actions/vehicleActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  vehicles?: Array<Vehicles>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const vehiclesReducer = (
  state: DefaultStateInterface = defaultState,
  action: VehicleDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case VEHICLES_FAILURE:
      return {
        loading: false,
      };
    case VEHICLES_LOADING:
      return {
        loading: true,
      };
    case VEHICLES_SUCCESS:
      return {
        loading: false,
        vehicles: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof vehiclesReducer>;

export default vehiclesReducer;